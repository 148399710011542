import { STATISTICS_UPDATE_DATA, STATISTICS_UPDATE_FILTERS } from './statistics.actions'

const initStatistics = {
    data: [],
    filters: {
        startDate: new Date('2017-01-01'),
        endDate: new Date(),
        action: [],
        sentVia: null,
        assigned:false,
        community: null,
        assessed:false,
        assessment: null,
        case_closed:false,
        outcome:null
    },
}

export function statisticsReducer(state = initStatistics, action) {
    switch(action.type) {
        case STATISTICS_UPDATE_DATA:
            return {...state, data: action.payload}
        case STATISTICS_UPDATE_FILTERS:
            // need to create a new filter object to make sure we don't mutate state
            const newFilter = {
                startDate: new Date(action.payload.startDate),
                endDate: new Date(action.payload.endDate),
                action: action.payload.action,
                sentVia: action.payload.sentVia,
                assigned: action.payload.assigned,
                community: action.payload.community,
                assessed: action.payload.assessed,
                assessment: action.payload.assessment,
                case_closed: action.payload.case_closed,
                outcome: action.payload.outcome
            }
            return {...state, filters: newFilter}
        default:
            return state
    }
}