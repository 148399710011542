import { ALERT_UPDATE_ALL, ALERT_UPDATE_CATEGORIES, ALERT_UPDATE_FILTERED, ALERT_UPDATE_DETAIL, ALERT_CLEAR_DETAIL, ALERT_CLEAR_FILTERED,
    ALERT_UPDATE_ALERT_PAGE_FILTERS, ALERT_UPDATE_DISPLAY_TYPE, ALERT_UPDATE_SHOWACKNOWLEDGEMODAL, ALERT_UPDATE_SHOWMESSAGECOMMUNITYMODAL, ALERT_UPDATE_SHOWVERIFICATIONMODAL,
    ALERT_UPDATE_SHOWFOLLOWUPMODAL, ALERT_REFRESH_ALERT_DETAIL, ALERT_UPDATE_NAVIGATE_TO_ALERT_ID, ALERT_DELETE_SHOWACKNOWLEDGEMODAL, ALERT_ASSIGN_SHOWCASEMODAL, ALERT_OUTCOME_UPDATE, ALERT_ASSESS_UPDATE } from './alert.actions'

const initAlert = {
    all: [],
    detail: null,
    categories: [],
    filtered: [],
    alertPageFilters: {
        startDate: new Date('2017-01-01'),
        endDate: new Date(),
        category: null,
        community: null,
        action: [],
        sentVia: [],
        assigned:false,
        assessed:false,
        assessment: null,
        case_closed:false,
        outcome:null
    },
    alertPageDisplayType: {
        list: true,
        map: false
    },
    showAcknowledgeModal: false,
    showMessageCommunityModal: false,
    showVerificationModal: false,
    showFollowupModal: false,
    refreshAlertDetail: 0,
    outcomes: [],
    assess: [],
    navigateToAlertId: null // used on image upload page to redirect after successful image upload
}

export function alertReducer(state = initAlert, action) {
    switch(action.type) {
        case ALERT_UPDATE_ALL:
            return {...state, all: action.payload}
        case ALERT_UPDATE_CATEGORIES:
            return {...state, categories: action.payload}
        case ALERT_UPDATE_FILTERED:
            return {...state, filtered: action.payload}
        case ALERT_OUTCOME_UPDATE:
            return {...state, outcomes: action.payload}
        case ALERT_ASSESS_UPDATE:
            return {...state, assess: action.payload}
        case ALERT_UPDATE_DETAIL:
            return {...state, detail: action.payload}
        case ALERT_CLEAR_FILTERED:
            return {...state, filtered: []}
        case ALERT_CLEAR_DETAIL:
            return {...state, detail: null}
        case ALERT_UPDATE_ALERT_PAGE_FILTERS:
            return {...state, alertPageFilters: action.payload}
        case ALERT_UPDATE_DISPLAY_TYPE:
            return {...state, alertPageDisplayType: action.payload}
        case ALERT_UPDATE_SHOWACKNOWLEDGEMODAL:
            return {...state, showAcknowledgeModal: action.payload}
        case ALERT_UPDATE_SHOWMESSAGECOMMUNITYMODAL:
            return {...state, showMessageCommunityModal: action.payload}
        case ALERT_UPDATE_SHOWVERIFICATIONMODAL:
            return {...state, showVerificationModal: action.payload}
        case ALERT_UPDATE_SHOWFOLLOWUPMODAL:
            return {...state, showFollowupModal: action.payload}
        case ALERT_REFRESH_ALERT_DETAIL:
            return {...state, refreshAlertDetail: state.refreshAlertDetail + 1}
        case ALERT_UPDATE_NAVIGATE_TO_ALERT_ID:
            return {...state, navigateToAlertId: action.payload}
        case ALERT_DELETE_SHOWACKNOWLEDGEMODAL:
            return {...state, showDeleteAcknowledgeModal: action.payload.show, alertToBeDeleted: action.payload.alert}
        case ALERT_ASSIGN_SHOWCASEMODAL:
            return {...state, showAssignCaseModal:action.payload}
        default:
            return state
    }
}